import React, { createContext, useEffect, useState } from "react"

const Store = ({ children }) => {
  const [cart, setCart] = useState([])
  const [toast, setToast] = useState(null)

  const addProduct = (product) => {
    console.log('Aggiungo prodotto....')
    const newCart = [...cart, product]
    console.log(newCart)
    setCart([...cart, product])
    localStorage.setItem('cart', JSON.stringify(newCart))
  }

  const updateCart = (newCart) => {
    console.log('Aggiorno cart....')
    setCart(newCart)
    localStorage.setItem('cart', JSON.stringify(newCart))
  }


  useEffect(() => {
    const init = async () => {
      const cartRes = await fetch('/api/m2/cart/load')
      const cartData = await cartRes.json()
      localStorage.setItem("cart", JSON.stringify(cartData.cart))
      setCart(cartData.cart)
    }
    init()
  }, [])

  useEffect(() => {
    if (toast) {
      setTimeout(() => {
        setToast(null)
      }, 5000)
    }
  }, [toast])

  return <Context.Provider value={{ cart, addProduct, updateCart, toast, setToast }}>{children}</Context.Provider>
}

export const Context = createContext()
export default Store